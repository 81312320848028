import "./home.scss";
//we add left and rigth and  picture  the picture 
import { init } from "ityped"; //animation
//import { isProxy } from "util/types";
import { useEffect, useRef } from "react";

export default function Home() {

    const textRef=useRef();
      
    useEffect( ()=>{
        init(textRef.current, { 
            showCursor: true, strings: [" Software Engineer"," Developer", " Designer" ] })
    },[]);

    return (
        <div className="home" id="home">
         <div className="left">
             <div className="imageContainer">
                 <img src="./assets/erikgarcia.jpg" alt="" />
             </div>
         </div>

         <div className="right">
             <div className="wrapper">
                <h2>Hi There, I'm</h2>
                <h1>Erik</h1>
                <h3><span ref={textRef}></span> </h3>
             </div>
             <a href="#projects">
                <img src="./assets/pc.jpg" alt="" />
             </a>
         </div>
        </div>
    )
}

