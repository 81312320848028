import "./services.scss"

export default function Services() {
    const data = [
        {
          id: 1,
          name: "",
          title: "",
          img:
            "https://www.reachcambridgetutors.com/wp-content/uploads/2020/09/rc-comp-sci@2x.png",
          icon:
           "./assets/C-logo.png",
          desc:
            "Expertise in the development of both front end and back end portions of web applications.",
        },
        {
          id: 2,
          name: "",
          title: "",
          img:
            "./assets/webdesign.jpg",
          icon: "./assets/java_logo.jpg",
          desc:
            "Skills in front-end lenguages and frameworks, backend technologies and frameworks, database management systems, version control, and web hosting.",
          featured: true,
        },
        {
          id: 3,
          name: "",
          title: "",
          img:
            "./assets/react.png",
          icon: "./assets/javascript.png",
          desc:
            "If you want to collaborate on crafting amazing projects, you can contact me via LinkedIn.",
        },
      ];
    
    return (
        <div className="services" id="services">
            
            <h1> Services</h1>
             <div className="container">
                 {data.map((d)=>(

                 
                 <div className={d.featured ? "card featured" : "card"}>   
                     <div className="top">
                     <img src=""className="left" alt="" />
                     
                     <img src={d.img} className="middle" alt="" />
                     
                     <img src={d.icon} className="right" alt="" />
                     </div>
                     <div className="center">
                        {d.desc}
                        
                    </div>
                     <div className="bottom">
                         <h3>{d.name}</h3>
                         <h4>{d.title}</h4>
                     </div>
                 </div>))}
             </div>

        </div>
    )
}

