import Topbar from "./componets/topbar/Topbar";
import Home from "./componets/home/Home";
import AboutMe from "./componets/about-me/AboutMe"
import Contact from "./componets/contact/Contact"
import Projects from "./componets/projects/Projects";
import Services from "./componets/services/Services";
import Bottombar from "./componets/bottombar/Bottombar";
import "./app.scss";
import { useState } from "react";
import Menu from "./componets/menu/Menu";

function App() {

  const[menuOpen,setMenuOpen] = useState(false) // false state for manue
  // we added to <Topbar/> for acive menue and
  // we addded <Menu/> and then for <Menu/>
  return (
    <div className="app">
      <Topbar menuOpen= {menuOpen} setMenuOpen={setMenuOpen} /> 
      <Menu menuOpen= {menuOpen} setMenuOpen={setMenuOpen}/>
      <div className="sections">
       <Home/>
       <AboutMe/>
       <Projects/>
       <Services/>
       {/* <Contact/> */}
      </div>
      <div className="bottom">
      <Bottombar/>
      </div>
    </div>
  );
}

export default App;
