import ProjectsList from "../projectsList/ProjectsList";
import "./projects.scss"
import { useEffect, useState } from "react";
import {
    applicationProjects,
    webAppProjects,
    articles,
}from"../../data";

export default function Projects() {

    const [selected,setSelected] = useState("web"); //this select the menue
    const [data,setData] = useState([]);
    const list =[
        {
            id: "web",
            title: "Web App",
        },
        {
            id: "application",
            title: "Aplication",
            
        },
        {
            id: "articles",
            title: "Articles",
        }, 
    ]; 

    useEffect(()=>{

        switch(selected){
            
            case "application":
                setData(applicationProjects);
                break;
            case "web":
                setData(webAppProjects);
                break;
            case "articles":
             setData(articles);
                break;
            default:
                setData(webAppProjects);
        }


        

    },[selected]);

    return (
        <div className="projects" id="projects">
            <h1>Projects</h1>
            
            <ul>
                {list.map((item) => (
                   <ProjectsList
                    title={item.title}
                    active={selected === item.id}
                    setSelected={setSelected}
                    id={item.id}                 
                   />
                ))}                
            
            </ul>

            <div className="container">

                {data.map((d)=>(              

                <div className="item">

                    <img src={d.img} alt=""/>
                    <h3>{d.title}</h3>
                
                </div>
                
                ))}

            </div>
            
        </div>
    );
}

