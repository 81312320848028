import "./aboutme.scss"
import { useState } from "react";

export default function Aboutme() {
        const [currentSlide, setCurrentSlide]=useState(0)
    const data = [
        {
          id: "1",
          icon: "./assets/pexels-karolina-grabowska-4386426.jpg",
          title: "Mission",
          desc:
            "Erik Garcia is an accomplished software engineer with experience in goverment contracting. Mr. Garcia supports government mission based solutions through software engineering roles.",
          img:
            "./assets/pexels-somchai-kongkamsri-172721.jpg",
        },
        {
          id: "2",
          icon: "./assets/erik-graduation.jpg",
          title: "Education",
          desc:
            "Erik received a Bachelor’s degree in  Computer Science from George Mason University",
          img:
            "./assets/berd1.jpg"
        },
        {
          id: "3",
          icon: "./assets/erik3.jpg",
          title: "Interests and Hobbies",
          desc:
            "In addition to Computer Science, Erik finds fascinating other fields of knowledge such psychology and economics. Erik enjoys music and going to the gym.",
          img:
            "./assets/erik-ny.JPG",
        },
      ];
    
      const handleClick = (way)=>{
          way === "left" ? setCurrentSlide(currentSlide > 0 ? currentSlide-1 : 2) :
          setCurrentSlide(currentSlide<data.length -1 ?currentSlide +1 : 0)
      }
    return (
        <div className="aboutme" id="aboutme">
            <h1>About Me</h1>
            <div className="slider" style={{transform:`translateX(-${currentSlide * 100}vw)` }} >
               
                {data.map((d)=>(     
                <div className="container">
                    <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <div className="imgContainer">
                                    <img src={d.icon} alt="" />
                                </div>
                                <h2>{d.title} </h2>
                                <p> {d.desc}</p>
                                <span>EFG</span>

                            </div>
                        </div>

                        <div className="right">
                        <img src={d.img} alt="" />
                        </div>
                    </div>
                
                </div>))}
            </div>
            
            <img src="./assets/arrow.png" className="arrow left" alt=""  onClick={()=>handleClick("left")}/>
            <img src="./assets/arrow.png" className="arrow right" alt="" onClick={()=>handleClick()}/>
        </div>
    )
}


