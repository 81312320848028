
  
  export const webAppProjects = [
    {
      id: 1,
      title: <a href="https://efg-ecommerce-example.netlify.app/">E-Commerce Website</a>,
      img:  "./assets/efgstore.PNG",
    },
    {
      id: 2,
      title: <a href="https://portfolio-efg-sample.netlify.app/"> Portfolio Website</a>,
      img: "./assets/portfolio-efg-sample.png",
   
       },
    {
      id: 3,
      title: <a href="https://taxservicesdemo.netlify.app">Tax Business</a>,
      img: "https://taxservicesdemo.netlify.app/static/media/needhelp.7f98a6911d629c770d96.png",
      
    },
    {
      id: 4,
      title: <a href="http://mason.gmu.edu/~egarci4/index.html">School Projects</a>,
      img: "./assets/schoolprojects.png",
    },
    
  ];
  export const applicationProjects = [
    {
      id: 1,
      title: <a href="https://github.com/erikfgarcia">Electrician Tool Kit</a>,
      img:
        "./assets/electricapplication.jpg",
    },
    {
      id: 2,
      title: <a href="https://github.com/erikfgarcia">Movie Database</a>,
      img:
        "./assets/moviedatabase.jpg",
    },
    {
      id: 3,
      title: <a href="https://github.com/erikfgarcia/Padding-Oracle-Attack-">Padding Oracle Attack</a>,
      img:
        "https://1do0x210e15c8plg913c4zhy-wpengine.netdna-ssl.com/wp-content/uploads/cryptography-scaled-e1593335036543.jpg",
    },
    {
      id: 4,
      title: <a href="https://github.com/erikfgarcia">GitHub Repository</a>,
      img:
        "./assets/erikgithub.PNG",
    },
    
  ];
  
  export const articles = [
    {
      id: 1,
      title: "Comming Soon!",
      img:
        "./assets/git.png",
    },
    {
      id: 2,
      title: "Comming Soon!",
      img:
        "./assets/linux.png",
    },
    {
      id: 3,
      title: "Comming Soon!",
      img:
        "./assets/maven.png",
    },
    {
      id: 4,
      title: "Comming Soon!",
      img:
        "./assets/docker.png",
    },
    {
      id: 5,
      title: "Comming Soon!",
      img:
        "./assets/kubernetes.png",
    },
    {
      id: 6,
      title: "Comming Soon!",
      img:
        "./assets/elasticsearch.png",
    },
  ];
  
  export const oneProjects = [
    {
      id: 1,
      title: "Design Social Media App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 2,
      title: "Design Rampa UI Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 3,
      title: "Design E-commerce Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 4,
      title: "Design Relax Mobile App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 5,
      title: "Design Keser Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 6,
      title: "Design Banking App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
  ];
  
  export const twoProjects = [
    {
      id: 1,
      title: "Content Social Media App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 2,
      title: "Content Rampa UI Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 3,
      title: "Content E-commerce Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 4,
      title: "Content Relax Mobile App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 5,
      title: "Content Keser Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 6,
      title: "Content Banking App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
  ];
  