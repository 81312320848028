import "./topbar.scss"
// import { BsTelephoneFill } from "react-icons/bs";
// import { BsFacebook } from "react-icons/bs";
import { BsLinkedin} from "react-icons/bs";
// import { FaInstagram} from "react-icons/fa";
// import { MdEmail} from "react-icons/md";
import { BsGithub} from "react-icons/bs";

// if you want the other icon and links paste this below 

    //   <div className="itemContainer">
    //                         <a href="https://www.facebook.com/erik.garcia.3914" target={"_blank"}> <BsFacebook className="icon"/> </a>
    //                         <span>  </span>
    //                     </div>
                       
    //                     <div className="itemContainer">
    //                     <a href="https://www.instagram.com/erikfgarcia_/" target={"_blank"}> <FaInstagram className="icon"/>  </a>
    //                         <span> </span>
    //                     </div> 

// left icon return to top(home) see left asign id to components 
// rigth we have some icons 

export default function Topbar({menuOpen, setMenuOpen}) { // we take these props  active menue
    return (
        <div className={"topbar " + (menuOpen && "active")} > 
                 
                 <div className="wrapper">
                    
                    <div className="left"> 
                         <a href="#home" className="logo">EFG</a>
                    </div>
                    
                    <div className="middle"> 

                        <div className="itemContainer">
                        <a href="https://www.linkedin.com/in/erikfgarcia" target={"_blank"}> <BsLinkedin className="icon"/>   </a> 
                            <span> </span>
                        </div>

                        <div className="itemContainer">
                        <a href="https://github.com/erikfgarcia" target={"_blank"}> <BsGithub className="icon"/> </a> 
                            <span> </span>
                        </div>                       
                          
                    </div>          
                    
                    <div className="right">
                        <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
                            <span className="line1"></span>
                            <span className="line2"></span>
                            <span className="line3"></span>
                        </div>
                    
                    </div>  

                </div>    
        </div>
    )
}
