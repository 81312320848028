import "./menu.scss"
// this onClick={()=>setMenuOpen(false) set the menu to false and it closes
export default function Menu({menuOpen,setMenuOpen }) {
    return (
        <div className={"menu " + (menuOpen && "active") }>
        <ul>
            <li onClick={()=>setMenuOpen(false)}>  
                <a href="#home">Home</a>
            </li>
        </ul>

        <ul>
            <li onClick={()=>setMenuOpen(false)}>
                <a href="#aboutme">About Me</a>
            </li>
        </ul>

        <ul>
            <li onClick={()=>setMenuOpen(false)} >
                <a href="#projects">Projects</a>
            </li>
        </ul>

        <ul>
            <li onClick={()=>setMenuOpen(false)}>
                <a href="#services">Services</a>
            </li>
        </ul>

        <ul>
            <li onClick={()=>setMenuOpen(false)}>
                <a href="#contact">Contact</a>
            </li>
        </ul>
            
        </div>
    )
}
